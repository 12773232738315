import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
export const ProjectQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(
      relativePath: { eq: "project/cardiac-signals-analysis/images/banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  ProjectQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Goal`}</h2>
    <p>{`Construction of a GUI and algorithm for processing and analysis of cardiac
signals from audio.`}</p>
    <h2>{`Context`}</h2>
    <p>{`This was my final project of the seventh semester for the subject of signals and
systems, here I put into practice everything I learned during the course of the
semester in this career, which includes the basic and most popular techniques
for analysis of signals and systems in the time and frequency domains, as they
are: operations between signals, convolution, and Fourier and Laplace
transforms.`}</p>
    <h2>{`Project Construction`}</h2>
    <p>{`The algorithm which you can find on GitHub allows you to do the following`}</p>
    <p><strong parentName="p">{`Time Domain.`}</strong>{` Calculates the maximum, minimum and mean values of:`}</p>
    <ul>
      <li parentName="ul">{`Duration of the interval of a cardiac cycle and of the segments S1, S2,
systole and diastole.`}</li>
      <li parentName="ul">{`Relationship between systolic interval and cardiac cycle`}</li>
      <li parentName="ul">{`Relationship between diastolic interval and cardiac cycle`}</li>
      <li parentName="ul">{`Relationship between systolic and diastolic interval.`}</li>
      <li parentName="ul">{`Maximum and minimum amplitude in the S1, S2, systole and diastole segments.`}</li>
      <li parentName="ul">{`Energy in the cardiac cycle, segment S1, S2 systole and diastole.`}</li>
      <li parentName="ul">{`Power in the cardiac cycle, segment S1, S2 systole and diastole.`}</li>
    </ul>
    <p><strong parentName="p">{`Frequency domain.`}</strong>{` Calculates the maximum, minimum and mean values of:`}</p>
    <ul>
      <li parentName="ul">{`Determine the interval of frequencies where more energy is concentrated (Start
frequency, end frequency, interval width) in a recording, a cardiac cycle,
segment S1, S2, systole and diastole.`}</li>
      <li parentName="ul">{`Calculate the energy in the frequency range.`}</li>
      <li parentName="ul">{`Calculate the power in the frequency range.`}</li>
      <li parentName="ul">{`Calculate the amplitude in the frequency interval. How often is the maximum
amplitude?`}</li>
      <li parentName="ul">{`Show the Spectrogram.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      